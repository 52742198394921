import React from 'react'
import * as styles from './Button.module.scss'
import clsx from 'clsx'
import { Link } from 'gatsby'
import Obfuscate from 'react-obfuscate'

const LinkButton = ({
  variant = 'primary',
  type = 'button',
  target = 'top',
  href,
  to,
  children,
  email = null,
  tel = null,
  className,
  download,
}) => {
  const _className = clsx([
    styles.button,
    styles.linkButton,
    styles[variant],
    className,
  ])

  const props = { className: _className, type, target, to, href, download }

  if (to) {
    return <Link {...props}>{children}</Link>
  }

  if (email || tel) {
    return <Obfuscate {...props}>{children}</Obfuscate>
  }

  return <a {...props}>{children}</a>
}

export default LinkButton
