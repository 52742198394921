import React from 'react'
import * as styles from './Card.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import LinkButton from '../../common/Button/LinkButton'

const linkProps = {
  target: '_top',
  href: '/downloads/businesscard.pdf',
  download: 'eggsquare-businescard.pdf',
}

const Card = ({ showFront = true, showBack = false, showButton = true }) => (
  <div className={styles.container}>
    <div className={styles.images}>
      {showFront && (
        <a {...linkProps}>
          <StaticImage src="../../../images/business-card/front.png" alt={'Business Card Front'} width={400} />
        </a>
      )}

      {showBack && (
        <a {...linkProps}>
          <StaticImage src="../../../images/business-card/back.png" alt={'Business Card Back'} width={400} />
        </a>
      )}
    </div>

    {showButton && (
      <LinkButton // fixme: styles are wrong after gatsby build
        variant="primary"
        {...linkProps}
      >Download</LinkButton>
    )}
  </div>
)

export default Card
